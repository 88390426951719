import React, { Fragment, useEffect, useState } from "react";
import PageTItle from "../../layouts/PageTitle";
import data from "../../components/table/tableData.js";
import {
    Table,
    Pagination
} from "react-bootstrap";
import { Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import BasicDatatable from '../../components/table/BasicDatatable'
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import * as apiServices from '../../../services/apiServices'

const schema = yup.object().shape({
    first_name: yup.string().required('First Name is required'),
    last_name: yup.string().required('Last Name is required'),
    address: yup.mixed().required('Address is required'),
    city: yup.string().required('County is required'),
    state: yup.string().required('State is required'),
    zipcode: yup.string().required('Zipcode is required'),
    scope: yup.string().required('Scope is required'),
    phone: yup.string().required('Phone No is required'),
    email_id: yup.string().email().required('Email is required'),
    company_name: yup.string().required('Company is required'),
    cost: yup.mixed().required('Cost is required'),
});


export default function Contractor() {
    const [Sponsor, setSponsor] = useState("");
    const [fileUpload1checkbox, setfileUpload1checkbox] = useState(false);
    const [fileUpload2checkbox, setfileUpload2checkbox] = useState(false);
    const [fileUpload3checkbox, setfileUpload3checkbox] = useState(false);
    const [fileUpload4checkbox, setfileUpload4checkbox] = useState(false);
    const [btntitle, setbtntitle] = useState("Add Contractor");
    const [cardtitle, setcardtitle] = useState("List of Contractor");
    const [Showform, setShowform] = useState(false);
    const [usertable, setusertable] = useState([]);
    const [usersToBeShown, setusersToBeShown] = useState([]);
    const [currentPage, setcurrentPage] = useState(1);
    const [rowSize, setrowSize] = useState(15);
    const [sNo, setsNo] = useState(0);
    const [lastPage, setlastPage] = useState(1);
    const [searchInput, SetSearchInput] = useState("");
    const [pageSize, setpageSize] = useState(15);
    const [pageArray, setpageArray] = useState([]);
    const [activeuser, setactiveuser] = useState({});
    const [memberId, setMemberId] = useState("");


    const { register, handleSubmit, formState: { errors }, reset, setValue, getValues } = useForm({
        resolver: yupResolver(schema),
        // defaultValues: {
        //     member_id: '', 
        //     project_name: '', 
        //     last_paid_amount: '', 
        //     current_due_amount: '', 
        //     over_due_amount: '', 
        //     last_paid_date: '', 
        //     current_due_date: '', 
        //     over_due_date: '', 
        //     payment_method_others: '', 
        //   }
    });

    useEffect(() => {
        getMembers()
    }, [])

    
    useEffect(() => {
        calculatePaginationDetails(1, usertable, currentPage)
     }, [searchInput]);

    const FilteredData = () => {
        return usersToBeShown.filter(
            (user) =>
                user.first_name.toLowerCase().includes(searchInput.toLowerCase()) ||
                user.last_name.toLowerCase().includes(searchInput.toLowerCase()) ||
                user.city.toLowerCase().includes(searchInput.toLowerCase()) ||
                user.state.toLowerCase().includes(searchInput.toLowerCase()) ||
                user.company_name.toLowerCase().includes(searchInput.toLowerCase()) ||
                user.scope.toLowerCase().includes(searchInput.toLowerCase()) ||
                user.cost.toLowerCase().includes(searchInput.toLowerCase())
        );
    };

    const calculatePaginationDetails = (page, data, currPage) => {
        if (typeof page === "undefined") return;
        console.log(page)
        let users = data.filter(
            (user) =>
                user.first_name.toLowerCase().includes(searchInput.toLowerCase()) ||
                user.last_name.toLowerCase().includes(searchInput.toLowerCase()) ||
                user.city.toLowerCase().includes(searchInput.toLowerCase()) ||
                user.state.toLowerCase().includes(searchInput.toLowerCase()) ||
                user.company_name.toLowerCase().includes(searchInput.toLowerCase()) ||
                user.scope.toLowerCase().includes(searchInput.toLowerCase()) ||
                user.cost.toLowerCase().includes(searchInput.toLowerCase())
        );
        let total = users.length;
        let diff = users.length % pageSize !== 0 ? 1 : 0;
        let pages = Math.floor((users.length / pageSize) + diff);
        let firstPage = 1;
        let lastPage = pages;
        let pageArray = []
        let usersToBeShown = []
        let currentPage = currPage;
        if (page?.toString()?.toLowerCase()?.indexOf('previous') > 0) {
            currentPage = currentPage - 1;
            if (currentPage < 1) {
                currentPage = 1
            }
        } else if (page?.toString()?.toLowerCase()?.indexOf('next') > 0) {
            currentPage = currentPage + 1;
            if (currentPage > pages) {
                currentPage = pages;
            }
        } else if (page?.toString()?.toLowerCase()?.indexOf('first') > 0) {
            currentPage = 1
        } else if (page?.toString()?.toLowerCase()?.indexOf('last') > 0) {
            currentPage = pages;
        } else {
            currentPage = parseInt(page);
        }
        console.log(parseInt(page))
        console.log(currentPage)
        for (let i = currentPage; i <= currentPage + (rowSize - 1); i++) {
            if (i <= pages)
                pageArray.push(i)
        }
        let currentItemIndex = ((currentPage - 1) * pageSize);
        // currentItemIndex = currentItemIndex === 0 ? currentItemIndex : currentItemIndex + (currentPage - 1);
        for (let i = currentItemIndex; i < currentItemIndex + rowSize && i <= (total - 1); i++) {
            usersToBeShown.push(users[i])
        }
        let sno = parseInt(currentPage) * parseInt(pageSize) - parseInt(pageSize)
        let updatedState = {
            usersToBeShown: usersToBeShown,
            pageArray: pageArray,
            firstPage: firstPage,
            lastPage: lastPage,
            currentPage: currentPage,
            sNo: sno === 0 ? sno : sno - 1
        }
        console.log(updatedState)
        setusersToBeShown(usersToBeShown);
        setpageArray(pageArray);
        setcurrentPage(currentPage);
        setlastPage(lastPage);
        setsNo((parseInt(currentPage) * parseInt(pageSize) - parseInt(pageSize)) + parseInt(currentPage));

        // this.setState({
        //    usersToBeShown: usersToBeShown,
        //    pageArray: pageArray,
        //    firstPage: firstPage,
        //    lastPage: lastPage,
        //    currentPage: currentPage
        // });
    }

    const handlePagination = (e, txt) => {
        e.preventDefault();
        console.log(e.target);
        // if(txt != undefined) {
        calculatePaginationDetails(txt !== undefined ? txt : e.target.text, usertable, currentPage);
        // }
    }

    const onSearchChange = (e) => {
        // e.preventDefault();
        SetSearchInput(e);
        // calculatePaginationDetails(1, usertable, currentPage)
        setTimeout(() => {
            if (document.querySelector(".page-link"))
                document.querySelector(".page-link").click()
        }, 1000);
    }

    const onSubmit = (e) => {
        if (cardtitle === "Add Contractor") {
            apiServices.addContractor(getValues()).then(response => {
                notifySuccess(response.data[0].message);
                reset()
                cardbtn()
            }).catch(error => {

            })
        }
        else {
            apiServices.updateContractor(getValues(), memberId).then(response => {
                notifySuccess(response.data[0].message);
                reset()
                cardbtn()
            }).catch(error => {

            })
        }

    };


    const getMembers = () => {
        apiServices.getContractorList().then(response => {
            setusertable(response.data)
            calculatePaginationDetails(1, response.data, currentPage)
        }).catch(error => {

        })
    }

    function checkboxFun(option) {
        console.log(option)
    }


    function cardbtn() {
        if (btntitle === 'Add Contractor' || btntitle === 'Edit Contractor' || btntitle === 'View Contractor') {
            setbtntitle("Back");
            setcardtitle("Add Contractor")
            setactiveuser({})
            setShowform(true)
        } else {
            setbtntitle("Add Contractor");
            setcardtitle("List of Contractor")
            setactiveuser({})
            setShowform(false)
            getMembers()
            reset()
        }
    }

    function viewuser(e, user) {
        e.preventDefault()
        setbtntitle("Back");
        setcardtitle("View User")
        setactiveuser(user)
        setShowform(true)
        setValue('first_name', user.first_name)
        setValue('last_name', user.last_name)
        setValue('address', user.address)
        setValue('city', user.city)
        setValue('state', user.state)
        setValue('zipcode', user.zipcode)
        setValue('phone', user.phone)
        setValue('email_id', user.email_id)
        setValue('company_name', user.company_name)
        setValue('cost', user.cost)
        setValue('scope', user.scope)
    }

    function edituser(e, user) {
        e.preventDefault()
        setbtntitle("Back");
        setcardtitle("Edit Contractor")
        setactiveuser(user)
        setShowform(true)
        setMemberId(user.id)
        setValue('first_name', user.first_name)
        setValue('last_name', user.last_name)
        setValue('address', user.address)
        setValue('city', user.city)
        setValue('state', user.state)
        setValue('zipcode', user.zipcode)
        setValue('phone', user.phone)
        setValue('email_id', user.email_id)
        setValue('company_name', user.company_name)
        setValue('cost', user.cost)
        setValue('scope', user.scope)
    }

    function deleteuser(e, user) {
        e.preventDefault()
        setactiveuser(user)
        apiServices.deleteContractor(user.id).then(response => {
            notifyError();
            getMembers()
        }).catch(error => {

        })
    }
    const notifySuccess = (message) => {
        toast.success(message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    };
    const notifyError = () => {
        toast.error("User Deleted", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    };

    return (
        <Fragment>
            <PageTItle activeMenu="Add Contractor" motherMenu="Contractor" pageContent="Add Contractor" />
            <ToastContainer
                position="top-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
            <div className="col-xl-12 col-lg-12">
                <div className="card">
                    <div className="card-header">
                        <div className="col-xl-6 col-lg-6">
                            <h4 className="card-title">{cardtitle}</h4>
                        </div>
                        <div className="col-xl-6 col-lg-6 text-end">
                            <button type="submit" className="btn btn-primary" onClick={(e) => cardbtn(e)}>
                                {btntitle}
                            </button>
                        </div>
                    </div>
                    <div className="card-body">
                        {Showform === true
                            ?
                            <div className="basic-form">
                                <form onSubmit={handleSubmit(onSubmit)}>

                                    <div className="row">
                                        <div className="form-group mb-3 col-md-4">
                                            <label>First Name</label>
                                            <input
                                                {...register("first_name")}
                                                type="text"
                                                className="form-control"
                                                placeholder="First Name"
                                                disabled={cardtitle === "View User" ? true : false}
                                                value={activeuser?.['FIRST NAME']}
                                            />
                                            {errors.first_name && <small id="emailHelp" class="text-danger">{errors.first_name?.message}</small>}
                                        </div>
                                        <div className="form-group mb-3 col-md-4">
                                            <label>Last Name</label>
                                            <input
                                                {...register("last_name")}
                                                type="text"
                                                className="form-control"
                                                placeholder="Last Name"
                                                disabled={cardtitle === "View User" ? true : false}
                                                value={activeuser?.['LAST NAME']}
                                            />
                                            {errors.last_name && <small id="emailHelp" class="text-danger">{errors.last_name?.message}</small>}
                                        </div>
                                        <div className="form-group mb-3 col-md-4">
                                            <label>Address</label>
                                            <input
                                                {...register("address")}
                                                type="text"
                                                className="form-control"
                                                placeholder="Address"
                                                disabled={cardtitle === "View User" ? true : false}
                                                value={activeuser?.['ADDRESS']}
                                            />
                                            {errors.address && <small id="emailHelp" class="text-danger">{errors.address?.message}</small>}
                                        </div>


                                        <div className="form-group mb-3 col-md-4">
                                            <label>County</label>
                                            <input
                                                {...register("city")}
                                                type="text"
                                                className="form-control"
                                                placeholder="City"
                                                disabled={cardtitle === "View User" ? true : false}
                                                value={activeuser?.['CITY']}
                                            />
                                            {errors.city && <small id="emailHelp" class="text-danger">{errors.city?.message}</small>}
                                        </div>
                                        <div className="form-group mb-3 col-md-4">
                                            <label>State</label>
                                            <input
                                                {...register("state")}
                                                type="text"
                                                className="form-control"
                                                placeholder="State"
                                                disabled={cardtitle === "View User" ? true : false}
                                                value={activeuser?.['STATE']}
                                            />
                                            {errors.state && <small id="emailHelp" class="text-danger">{errors.state?.message}</small>}
                                        </div>
                                        <div className="form-group mb-3 col-md-4">
                                            <label>Zipcode</label>
                                            <input
                                                {...register("zipcode")}
                                                type="text"
                                                className="form-control"
                                                placeholder="Zipcode"
                                                disabled={cardtitle === "View User" ? true : false}
                                                value={activeuser?.['ZIP CODE']}
                                            />
                                            {errors.zipcode && <small id="emailHelp" class="text-danger">{errors.zipcode?.message}</small>}
                                        </div>
                                        <div className="form-group mb-3 col-md-4">
                                            <label>Phone</label>
                                            <input
                                                {...register("phone")}
                                                type="text"
                                                className="form-control"
                                                placeholder="Phone"
                                                disabled={cardtitle === "View User" ? true : false}
                                                value={activeuser?.['PHONE - HOME']}
                                            />
                                            {errors.phone && <small id="emailHelp" class="text-danger">{errors.phone?.message}</small>}
                                        </div>
                                        <div className="form-group mb-3 col-md-4">
                                            <label>Email</label>
                                            <input
                                                {...register("email_id")}
                                                type="text"
                                                className="form-control"
                                                placeholder="Email"
                                                disabled={cardtitle === "View User" ? true : false}
                                                value={activeuser?.['EMAIL']}
                                            />
                                            {errors.email_id && <small id="emailHelp" class="text-danger">{errors.email_id?.message}</small>}
                                        </div>
                                        <div className="form-group mb-3 col-md-4">
                                            <label>Company Name</label>
                                            <input
                                                {...register("company_name")}
                                                type="text"
                                                className="form-control"
                                                placeholder="Company Name"
                                                disabled={cardtitle === "View User" ? true : false}
                                                value={activeuser?.['COMPANY NAME']}
                                            />
                                            {errors.company_name && <small id="emailHelp" class="text-danger">{errors.company_name?.message}</small>}
                                        </div>
                                        <div className="form-group mb-3 col-md-4">
                                            <label>Cost</label>
                                            <input
                                                {...register("cost")}
                                                type="text"
                                                className="form-control"
                                                placeholder="Cost"
                                                disabled={cardtitle === "View User" ? true : false}
                                                value={activeuser?.['COST']}
                                            />
                                            {errors.cost && <small id="emailHelp" class="text-danger">{errors.cost?.message}</small>}
                                        </div>
                                        <div className="form-group mb-3 col-md-4">
                                            <label>Scope of Work</label>
                                            <select
                                                {...register("scope")}
                                                defaultValue={"option"}
                                                id="SponsorinputState"
                                                className="form-control"
                                                required
                                                value={activeuser?.['SCOPE']}
                                                //onChange={(e) => setSponsor(e.target.value)}
                                                disabled={cardtitle === "View User" ? true : false}
                                            >
                                                <option value="Electrical">Electrical</option>
                                                <option value="Plumbing">Plumbing</option>
                                                <option value="Engineer">Engineer</option>
                                                <option value="Installer">Installer</option>
                                                <option value="Soil Test">Soil Test</option>
                                            </select>
                                            {errors.scope && <small id="emailHelp" class="text-danger">{errors.scope?.message}</small>}
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="form-group mb-3 col-md-3">
                                        </div>
                                        {cardtitle !== "View User" &&
                                            <>
                                                <div className="form-group mb-3 col-md-3">
                                                    <button type="submit" className="btn btn-primary">
                                                        Save
                                                    </button>
                                                </div>
                                                <div className="form-group mb-3 col-md-3">
                                                    <button type="submit" className="btn btn-warning" onClick={(e) => cardbtn(e)}>
                                                        Cancel
                                                    </button>
                                                </div>
                                            </>
                                        }

                                        <div className="form-group mb-3 col-md-3"></div>
                                    </div>
                                </form>
                            </div>
                            :
                            <>
                                <div className="basic-form">
                                    <div className="row">
                                        <div className="form-group mb-3 col-md-4">
                                            <input type="text" className="form-control" placeholder="Search" value={searchInput} onChange={(e) => onSearchChange(e.target.value)} />
                                        </div>
                                    </div>
                                </div>
                                <br />
                                <Table responsive className="table table-striped">
                                    <thead>
                                        <tr>
                                            <th>
                                                <strong>S NO.</strong>
                                            </th>
                                            <th>
                                                <strong>FIRST NAME</strong>
                                            </th>
                                            <th>
                                                <strong>LAST NAME</strong>
                                            </th>
                                            <th>
                                                <strong>COUNTY</strong>
                                            </th>
                                            <th>
                                                <strong>STATE</strong>
                                            </th>
                                            <th>
                                                <strong>COMPANY</strong>
                                            </th>
                                            <th>
                                                <strong>SCOPE OF WORK</strong>
                                            </th>
                                            <th>
                                                <strong>COST</strong>
                                            </th>
                                            <th>
                                                <strong>Actions</strong>
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {FilteredData().map((item, i) => {
                                            console.log("mykeys", i, parseInt(currentPage) * parseInt(pageSize) - parseInt(pageSize));
                                            return [
                                                <tr key={i}>
                                                    <td>{(parseInt(currentPage) * parseInt(pageSize) - parseInt(pageSize)) + 1 + i}</td>
                                                    <td>{item.first_name}</td>
                                                    <td>{item.last_name}</td>
                                                    <td>{item.city}</td>
                                                    <td>{item.state}</td>
                                                    <td>{item.company_name}</td>
                                                    <td>{item.scope}</td>
                                                    <td>{item.cost}</td>
                                                    <td><div className="d-flex">
                                                        <Link
                                                            onClick={(e) => viewuser(e, item)}
                                                            className="btn btn-warning shadow btn-xs sharp me-1"
                                                        >
                                                            <i className="fa fa-eye"></i>
                                                        </Link>
                                                        <Link
                                                            onClick={(e) => edituser(e, item)}
                                                            className="btn btn-primary shadow btn-xs sharp me-1"
                                                        >
                                                            <i className="fa fa-pencil"></i>
                                                        </Link>
                                                        <Link
                                                            onClick={(e) => deleteuser(e, item)}
                                                            className="btn btn-danger shadow btn-xs sharp"
                                                        >
                                                            <i className="fa fa-trash"></i>
                                                        </Link>
                                                    </div></td>
                                                </tr>
                                            ];
                                        })}
                                    </tbody>
                                </Table>
                                <br />
                                {FilteredData().length > 0 &&
                                    <Pagination>
                                        <Pagination.First onClick={(e) => handlePagination(e, ">First")} />
                                        <Pagination.Prev onClick={(e) => handlePagination(e, ">Previous")} />{
                                            pageArray && pageArray.length &&
                                            pageArray.map(
                                                (item) => (
                                                    <Pagination.Item key={item} onClick={(e) => handlePagination(e)}
                                                        active={currentPage == item}>{item}</Pagination.Item>
                                                )
                                            )
                                        }
                                        <Pagination.Next onClick={(e) => handlePagination(e, ">Next")} />
                                        <Pagination.Last onClick={(e) => handlePagination(e, ">Last")} />
                                    </Pagination>
                                }
                            </>
                        }
                    </div>
                </div>
            </div>

        </Fragment>
    )
}